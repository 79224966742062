/**
TODO:
**/
class KeyValuePair {
  /**
   TODO:
   @param {array} keyValueList
   @ret
  **/
  constructor(keyValueList) {
    this.keyValuePair={};
    for (let index=0; index<keyValueList.length; index++) {
      const key=keyValueList[index].key;
      const value=keyValueList[index].value;
      this.keyValuePair[key]=value;
    }
  }
  /**
   TODO:
   @param {String} key
   @return {String} value mapped to key
  **/
  getValue(key) {
    return this.keyValuePair[key]||'';
  }
}
module.exports = {
  KeyValuePair,
};

